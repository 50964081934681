export const preDefinedStyles = {
  heading: [
    {
      identifier: "_default",
      name: "Default",
      cssClasses: {
        h3: "text-2xl font-bold",
        h2: "text-3xl font-bold",
        h1: "text-4xl font-bold",
      },
      cssStyle: ``,
    },
    {
      identifier: "leftborder",
      name: "Left border",
      settings: {
        "settings.general.marginTop": { value: 10 },
        "settings.general.padding": { value: 10, status: "disabled" },
        "settings.general.background": { value: "#ffcc00" },
      },
      cssClasses: {
        root: "border-l-8 border-blue-800 pl-2",
        h2: "text-3xl font-bold",
      },
      cssStyle: ``,
    },
  ],

  tabs: [
    {
      identifier: "_default",
      name: "Default",
      cssClasses: {},
      cssStyle: `
      div[role='tablist']{
        border-bottom: 1px solid #cccccc;
        padding-left: 10px;

         .dme-w-nav-item{
          padding: 10px 15px;
          border: none;
          cursor: pointer;
          background: white;
          font-size: 1rem;
        }      

        .dme-w-nav-item:hover{
          color: rgb(27, 112, 41);
        }

        .dme-w-nav-item.dme-w-active{
            border-top: 1px solid #cccccc;
            border-left: 1px solid #cccccc;
            border-right: 1px solid #cccccc;
            border-bottom: 1px solid white;
            margin-bottom: -1px;
            border-radius: 4px 4px 0px 0px;
            color: rgb(27, 112, 41);
        }

    }

    div[role='tabpanel']{
      padding: 10px;

    }
            `,
    },
  ],
  "collapsable-text": [
    {
      identifier: "_default",
      name: "Default",
      cssClasses: {
        button:
          "bg-blue-800 hover:text-white border text-sm border-blue-900 text-white py-2 px-4 hover:opacity-90",
      },
      cssStyle: `
           
        `,
    },
  ],
  popup: [
    {
      identifier: "_default",
      name: "Default",
      cssClasses: {
        button:
          "bg-blue-800 hover:text-white border text-sm border-blue-900 text-white py-2 px-4 hover:opacity-90",
        container: "!p-4",
      },
      cssStyle: `
           
        `,
    },
  ],
  table: [
    {
      identifier: "_default",
      name: "Default",
      cssStyle: `
            .dme-viewmode-mobile & {
                max-width: 100%;
                overflow: auto;
            }
        `,
    },
  ],
  text: [
    {
      identifier: "paragraph",
      name: "Paragraph",
      cssStyle: `
           p{
             margin-top: 10px;
           }
        `,
    },
    {
      identifier: "alert-info",
      name: "Alert - info",
      cssClasses: {
        root: "bg-green-100 border-l-4 border-green-500 text-green-700 p-4",
      },
      cssStyle: `          
        `,
    },
    {
      identifier: "alert-warning",
      name: "Alert - warning",
      cssClasses: {
        root: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4",
      },
      cssStyle: `          
        `,
    },
  ],
  gallery: [
    {
      identifier: "_default",
      name: "Default",
      cssStyle: `           
            .dme-w-pagination-container{
                margin-top: 20px;
            }

            .dme-w-pagination-item{
              padding: 5px 10px;
              border: 1px solid #cccccc;
              margin-left: 5px;
              display:inline-block;
             margin-bottom: 10px;
            }

            .dme-w-indicator{
                font-size: 14px;
                color: white;
                text-shadow: 1px 1px 1px #333333;
            }

            .dme-w-pagination-item-current{
              font-weight: bold;
            }            
        `,
    },
  ],
};
